<template>
  <div class="card shadow bg-default">
    <div class="card-header border-0 bg-default">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0 text-white">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <router-link :to="{name: 'add-sponsoring'}" type="button" class="btn btn-primary btn-sm text-uppercase"><i
              class="ni ni-fat-add"></i> Ajouter un parrainage
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-items-center table-dark table-flush">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="sort" data-sort="name">NOM</th>
          <th scope="col" class="sort" data-sort="name">COMPTE</th>
          <th scope="col" class="sort" data-sort="name">PLAN</th>
          <th scope="col" class="sort" data-sort="name">PAYS</th>
          <th scope="col" class="sort" data-sort="name">TELEPHONE</th>
          <th scope="col" class="sort" data-sort="name">DATE INSCRIPTION</th>
        </tr>
        </thead>
        <tbody class="list">
        <tr v-for="(item, index) in displayData" :key="index">
          <td>
            <div class="media align-items-center bg-transparent">
              <a href="javascript:void(0)" class="avatar rounded-circle mr-3 w-12 bg-transparent">
                <img class="w-12 h-12" :alt="item.name" :src="item.image">
              </a>
              <div class="media-body">
                <b class="name mb-0 text-sm text-uppercase">{{ item.name }}</b>
              </div>
            </div>
          </td>
          <td>
            <b>{{ item.account }}</b>
          </td>
          <td class="budget">
            <b>{{ item.plan }}</b>
          </td>
          <td>
            <b>
              <div class="media align-items-center bg-transparent">
                <a href="javascript:void(0)" class="avatar rounded-circle mr-3 w-12 bg-transparent">
                  <img class="w-12 h-12" :alt="item.country.name" :src="item.country.flag">
                </a>
                <div class="media-body">
                  <b class="name mb-0 text-sm text-uppercase">{{ item.country.name }}</b>
                </div>
              </div>
            </b>
          </td>
          <td>
            <b>{{ `+${item.country.code} ${item.phone}` }}</b>
          </td>
          <td class="text-uppercase">
            <b>{{ format(item.date) }}</b>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer d-flex justify-content-end mt-4 bg-default">
      <base-pagination :next-page="nextPage" :prev-page="prevPage" :go-to="goTo" :per-page="perPage"
                       :total="data.length" :value="currentPage"/>
    </div>

  </div>
</template>

<script>
import {Utils} from "@/utils/utils";
import {format, register} from "timeago.js";

export default {
  name: "SponsorshipTable", props: {
    title: String,
    data: {
      type: Array
    },
    perPage: Number,

  },
  data: () => ({
    displayData: [],
    currentPage: 1,
  }),
  methods: {
    format(date) {
      const localeFunc = (number, index) => {
        return [
          ['A l\'instant', 'A l\'instant'],
          ['Il y a %s secondes', 'dans %s secondes'],
          ['Il y 1 minute', 'Dans 1 minute'],
          ['Il y a %s minutes', 'Dans %s minutes'],
          ['Il y a 1 heure', 'Dans 1 heure'],
          ['Il y a %s heures', 'dans %s heures'],
          ['Hier', 'Demain'],
          ['Il y a %s jours', 'Dans %s jours'],
          ['Il y a une semaine', 'Dans 1 semaine'],
          ['Il y a %s semaines', 'Dans %s semaines'],
          ['Il y a 1 mois', 'Dans 1 mois'],
          ['Il y %s mois', 'Dans %s mois'],
          ['Il y a 1 an', 'Dans 1 an'],
          ['Il y a %s ans', 'Dans %s ans']
        ][index];
      }
      register('fr_FR', localeFunc);
      return format(date, 'fr_FR')
    },
    prevPage: function () {
      this.currentPage = this.currentPage === 0 ? 0 : this.currentPage - 1
    },
    nextPage: function () {
      this.currentPage = this.currentPage === Math.ceil(this.data.length / this.perPage) ? Math.ceil(this.data.length / this.perPage) : this.currentPage + 1
    },
    goTo: function (index) {
      this.currentPage = index
    },
    priceFormatter: function (price) {
      return Utils.priceFormatter(price)
    }
  },
  mounted() {
    this.displayData = this.data.slice(0, this.perPage)
  },
  watch: {
    currentPage: function (_new) {
      this.displayData = this.data.slice((_new - 1) * this.perPage, this.perPage * _new)
    }
  }
}
</script>

<style scoped>

</style>