<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="d-flex mb-6 flex-column flex-md-row">
          <div class="avatar_section">
            <img :src="client.account.user.avatar" alt="">
          </div>
          <div class="info_section d-flex flex-column text-uppercase text-white justify-content-around ml-2">
            <b>{{ client.account.user.name }}</b>
            <b>+{{ client.account.user.country.phoneCode }} {{ client.account.user.phoneNumber }}</b>
            <b>{{
                client.account.user.email !== "" && client.account.user.email !== null ? client.account.user.email : '(NON RENSEIGNé)'
              }}</b>
            <b>{{ priceFormatter(client.balance) }} FCFA</b>
            <b>{{ client.account.accountNumber }}</b>
            <b class="text-uppercase" v-if="client.subscription.billingPlan.name !== null">PLAN
              {{ client.subscription.billingPlan.name }}</b>
            <b v-else>(PAS DE SOUSCRIPTION)</b>
            <b class="text-uppercase" v-if="client.subscription.startDate !== null">Du
              {{ new Date(client.subscription.startDate).toLocaleDateString() }} au
              {{ new Date(client.subscription.endDate).toLocaleDateString() }}</b>
          </div>
          <div>
            <button v-if="employee.role === 'ADMIN'" @click="resetAvatar"
                    type="button"
                    class="btn btn-success btn-outline-success btn-sm text-uppercase">Regénérer l'avatar
            </button>
            <button v-if="employee.role === 'ADMIN'" @click="flushConnections"
                    type="button"
                    class="btn btn-warning btn-outline-warning btn-sm text-uppercase">
              RETIRER LES CONNEXIONS
            </button>
          </div>
        </div>
        <TransactionsTable v-if="transactions.length !== 0" :per-page="15" title="Dernières transactions"
                           :data="transactions"/>
      </div>
    </div>
  </base-header>
</template>

<script>
import TransactionsTable from "@/views/Tables/TransactionsTable";
import {Utils} from "@/utils/utils";

export default {
  name: "User",
  components: {TransactionsTable},
  data: () => ({
    account: '',
    key: '',
    api: '',
    client: null,
    employee: null,
    transactions: []
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    },
    priceFormatter(price) {
      return Utils.priceFormatter(Number.parseInt(price))
    },
    async fetchClients() {
      await this.generateToken()
      fetch(`${this.api}/clients/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        if (res.length === 0) this.$router.back()
        this.client = res[0]
      }).catch(err => console.error(err))
    },
    async fetchTransactions() {
      fetch(`${this.api}/clients-transactions/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        if (res.length === 0) this.$router.back()
        this.transactions.push(...res[0].transactions)
      }).catch(err => console.error(err))
    },
    async flushConnections(){
      const account = this.client.account.accountNumber
      fetch(`${this.api}/flush-connections`, {
        method: 'POST',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        },
        body: JSON.stringify({account})
      }).then(res => res.json()).then(() => this.$router.back()).catch(err => console.error(err))
    },
    async resetAvatar() {
      const name = this.client.account.user.name
      const uuid = this.client.account.user.uuid
      fetch(`${this.api}/reset-avatar`, {
        method: 'POST',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        },
        body: JSON.stringify({uuid, name})
      }).then(res => res.json()).then(() => this.$router.back()).catch(err => console.error(err))
    }
  },
  mounted() {
    if (this.$route.params.account === undefined || this.$route.params.account === null)
      this.$router.back()
    this.account = this.$route.params.account
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.fetchClients().then(() => {
      this.fetchTransactions()
    })
  }
}
</script>

<style scoped>

</style>
