<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Transactions hebdomadaires</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light"
                  :data="tableData">
        <template slot="columns">
          <th>Type</th>
          <th>Nombre</th>
          <th>Valeur</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            {{ row.name }}
          </th>
          <td>
            {{ row.visitors }}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <span class="mr-2">{{ row.progress }}%</span>
              <base-progress :type="row.progressType"
                             class="pt-0"
                             :show-percentage="false"
                             :value="row.progress"
              />
            </div>
          </td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
export default {
  name: 'social-traffic-table',
  props: {
    data: {
      type: Array
    }
  },
  data: () => ({
    tableData: [
      {
        name: 'Dépot',
        visitors: 0,
        progress: 0,
        progressType: 'gradient-danger',
      },
      {
        name: 'Retrait',
        visitors: 0,
        progress: 0,
        progressType: 'gradient-success',
      },
      {
        name: 'Recharge',
        visitors: 0,
        progress: 0,
        progressType: 'gradient-primary',
      },
      {
        name: 'Décharge',
        visitors: 0,
        progress: 0,
        progressType: 'gradient-info',
      },
      {
        name: 'Transfert',
        visitors: 0,
        progress: 0,
        progressType: 'gradient-warning',
      }
    ]

  }),
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      })
      return map
    },
    organize(_d) {
      const data = _d.filter(item => {
        const d = new Date()
        return new Date(item.date).getTime() >= (d.getTime() - 1000 * 60 * 60 * 24 * 7)
      })
      let total = 0;
      data.forEach(item => total += item.amount)
      const groups = this.groupBy(data, d => d.type)

      const deposit = groups.get('DEPOSIT')
      const withdrawal = groups.get('WITHDRAWAL')
      const transfer = groups.get('TRANSFER')
      const supply = groups.get('SUPPLY')
      const discharge = groups.get('DISCHARGE')


      let depositTotal = 0
      let withdrawalTotal = 0
      let transferTotal = 0
      let supplyTotal = 0
      let dischargeTotal = 0


      if (deposit !== undefined)
        deposit.forEach(it => depositTotal += it.amount)
      if (supply !== undefined)
        supply.forEach(it => supplyTotal += it.amount)
      if (transfer !== undefined)
        transfer.forEach(it => transferTotal += it.amount)
      if (discharge !== undefined)
        discharge.forEach(it => dischargeTotal += it.amount)
      if (withdrawal !== undefined)
        withdrawal.forEach(it => withdrawalTotal += it.amount)

      this.tableData[0].progress = Math.round((depositTotal / total) * 100)
      this.tableData[0].visitors = deposit !== undefined ? deposit.length : 0
      this.tableData[1].progress = Math.round((withdrawalTotal / total) * 100)
      this.tableData[1].visitors = withdrawal !== undefined ? withdrawal.length : 0
      this.tableData[2].progress = Math.round((supplyTotal / total) * 100)
      this.tableData[2].visitors = supply !== undefined ? supply.length : 0
      this.tableData[3].progress = Math.round((dischargeTotal / total) * 100)
      this.tableData[3].visitors = discharge !== undefined ? discharge.length : 0
      this.tableData[4].progress = Math.round((transferTotal / total) * 100)
      this.tableData[4].visitors = transfer !== undefined ? transfer.length : 0
    }
  },
  mounted() {
    this.organize(this.data)
  }

}
</script>
<style>
</style>
