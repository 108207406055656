<template>
  <div>
    <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row mt-1">
        <div class="col">
          <BillingPlanTable :disable="disablePlan" title="PLANS DE TARIFICATION" :data="plans"/>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col">
          <TransactionFeeTable :data="fees" title="GRILLE TARIFAIRE RETRAIT"/>
        </div>
      </div>

      <div class="row mt-6">
        <div class="col">
          <CountriesTable :disable="disable" v-if="countries.length !== 0" :data="countries" title="PAYS DISPONIBLES"/>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import BillingPlanTable from "@/views/Tables/BillingPlanTable";
import TransactionFeeTable from "@/views/Tables/TransactionFeeTable";
import CountriesTable from "@/views/Tables/CountriesTable";

import {Utils} from "@/utils/utils";

export default {
  name: "Tarification",
  components: {CountriesTable, TransactionFeeTable, BillingPlanTable},
  data: () => ({
    plans: [],
    fees: [],
    countries: [],
    key: '',
    api: '',
    employee: null
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    }, disable(item) {
      fetch(`${this.api}/countries`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'connection': 'keep-alive',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        },
        body: JSON.stringify({
          old: item.code,
          code: item.code,
          name: item.name,
          operational: !item.operational,
          phoneCode: item.phoneCode,
          phoneSize: item.phoneSize
        })
      }).then(res => res.json()).then(res => {
        if (res.code && res.flag && res.name && res.phoneCode && res.phoneSize) {
          window.location.reload()
        }
      }).catch(err => this.error = err.message)
    },
    disablePlan(item) {
      fetch(`${this.api}/billing-plans`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'connection': 'keep-alive',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        },
        body: JSON.stringify({
          uuid: item.uuid,
          maxTransaction: item.maxAmount,
          maxConnections: item.maxConnections,
          name: item.name,
          visible: !item.visible,
          cost: item.cost
        })
      }).then(res => res.json()).then(res => {
        if (res.success && res.success === true) {
          window.location.reload()
        }
      }).catch(err => this.error = err.message)
    },
    async fetchData() {
      await this.generateToken()

      fetch(`${this.api}/tarifications`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        if (res.plans !== undefined && res.fees !== undefined) {
          this.plans = res.plans
          this.fees = res.fees
        } else {
          this.$router.push('/')
        }
      }).catch(err => console.error(err))
    },
    async fetchCountries() {
      fetch(`${this.api}/countries`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        this.countries = res
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.fetchData()
    this.fetchCountries()
  }
}
</script>

<style scoped>

</style>