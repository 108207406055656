<template>
  <div class="card shadow bg-default">
    <div class="card-header border-0 bg-default">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0 text-white">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <router-link :to="{name: 'add-country'}" type="button" class="btn btn-primary btn-sm text-uppercase">
            <i
                class="ni ni-fat-add"></i> Ajouter
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-items-center table-dark table-flush">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="sort" data-sort="name">CODE</th>
          <th scope="col" class="sort" data-sort="name">NOM</th>
          <th scope="col" class="sort" data-sort="name">PHONE CODE</th>
          <th scope="col" class="sort" data-sort="name">PHONE SIZES</th>
          <th scope="col" class="sort" data-sort="name">FLAG</th>
          <th scope="col" class="sort" data-sort="name">OPERATIONAL</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="list">
        <tr v-for="(item, index) in displayData" :key="index">
          <td>
            <b>{{ item.code }}</b>
          </td>
          <td>
            <b>{{ item.name }}</b>
          </td>
          <td class="budget">
            <b>{{ item.phoneCode }}</b>
          </td>
          <td>
            <b>{{ item.phoneSize }}</b>
          </td>
          <td>
            <div class="media align-items-center bg-transparent">
              <a href="javascript:void(0)" class="avatar rounded-circle mr-3 w-12 bg-transparent">
                <img class="w-12 h-12" :alt="item.name" :src="item.flag">
              </a>
            </div>
          </td>
          <td>
            <b> {{ item.operational ? 'OUI' : 'NON' }} </b>
          </td>
          <td>
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <router-link :to="{name: 'edit-country', params: {action: 'edit', country: item}}"
                             class="dropdown-item">
                  <i class="ni ni-air-baloon"></i><b class="text-uppercase">Modifier</b>
                </router-link>
                <button @click="disable(item)" class="dropdown-item">
                  <i class="ni ni-ui-04"></i><b class="text-uppercase">{{
                    item.operational ? 'Désactiver' : 'Activer'
                  }}</b>
                </button>
              </template>
            </base-dropdown>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer d-flex justify-content-end mt-4 bg-default">
      <base-pagination :next-page="nextPage" :prev-page="prevPage" :go-to="goTo" :per-page="perPage"
                       :total="data.length" :value="currentPage"/>
    </div>

  </div>
</template>

<script>
import {Utils} from '@/utils/utils'

export default {
  name: "CountriesTable",
  props: {
    title: String,
    data: {
      type: Array
    },
    disable: {
      type: Function
    },
    perPage: Number,

  },
  data: () => ({
    displayData: [],
    currentPage: 1,
  }),
  methods: {
    prevPage: function () {
      this.currentPage = this.currentPage === 0 ? 0 : this.currentPage - 1
    },
    nextPage: function () {
      this.currentPage = this.currentPage === Math.ceil(this.data.length / this.perPage) ? Math.ceil(this.data.length / this.perPage) : this.currentPage + 1
    },
    goTo: function (index) {
      this.currentPage = index
    },
    priceFormatter(price) {
      return Utils.priceFormatter(Number.parseInt(price))
    },
  },
  mounted() {
    this.displayData = this.data.slice(0, this.perPage)
  },
  watch: {
    currentPage: function (_new) {
      this.displayData = this.data.slice((_new - 1) * this.perPage, this.perPage * _new)
    }
  }
}
</script>

<style scoped>
.w-12 {
  width: 32px !important;
}

.h-12 {
  width: 32px !important;
}

</style>