<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">5 dernières transactions</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light"
                  :data="data.slice(0,5)">
        <template slot="columns">
          <th>EMETTEUR</th>
          <th>RECEPTEUR</th>
          <th>MONTANT</th>
          <th>TYPE</th>
          <th>DATE</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            {{ row.from.user.name }}
          </th>
          <td>
            {{ row.to.user.name }}
          </td>
          <td>
            {{ row.amount }}
          </td>
          <td>
            {{ row.type }}
          </td>
          <td>
            {{ format(row.date) }}
          </td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
import {format, register} from "timeago.js"

export default {
  name: 'page-visits-table',

  data: () => ({
    key: '',
    api: '',
    employee: null
  }),
  props: {
    data: {
      type: Array
    }
  },
  methods: {

    format(date) {
      const localeFunc = (number, index) => {
        return [
          ['A l\'instant', 'A l\'instant'],
          ['Il y a %s secondes', 'dans %s secondes'],
          ['Il y 1 minute', 'Dans 1 minute'],
          ['Il y a %s minutes', 'Dans %s minutes'],
          ['Il y a 1 heure', 'Dans 1 heure'],
          ['Il y a %s heures', 'dans %s heures'],
          ['Hier', 'Demain'],
          ['Il y a %s jours', 'Dans %s jours'],
          ['Il y a une semaine', 'Dans 1 semaine'],
          ['Il y a %s semaines', 'Dans %s semaines'],
          ['Il y a 1 mois', 'Dans 1 mois'],
          ['Il y %s mois', 'Dans %s mois'],
          ['Il y a 1 an', 'Dans 1 an'],
          ['Il y a %s ans', 'Dans %s ans']
        ][index];
      }
      register('fr_FR', localeFunc);
      return format(date, 'fr_FR')
    }
  }

}
</script>
<style>
</style>
