<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h1>Authentifiez-vous</h1>
          </div>
          <form role="form">
            <base-input class="input-group-alternative mb-3"
                        placeholder="Numéro de compte"
                        addon-left-icon="ni ni-single-02"
                        v-model="model.account">
            </base-input>

            <base-input class="input-group-alternative "
                        placeholder="Mot de passe"
                        input_type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="model.password">
            </base-input>

            <div v-if="error.toString().trim().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
                 role="alert">
              <strong>{{ error }} </strong>
              <button type="button" class="close"
                      @click="nullError"
                      data-dismiss="alert"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="text-center ">
              <base-button @click="login" type="default" class="my-4 btn-block" v-if="!loading">Valider</base-button>
              <base-button @click="login" type="default"
                           class="my-4 btn-block d-flex justify-content-center align-items-center" v-else><i
                  class="ni ni-settings-gear-65 loading"></i> Valider
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Utils} from "@/utils/utils";

export default {
  name: 'login',
  data() {
    return {
      api: '',
      model: {
        account: '',
        password: '',
      },
      error: '',
      loading: false
    }
  },
  methods: {
    nullError() {
      this.error = ''
    },
    login() {
      if (this.model.account.trim().length !== 0 && this.model.password.trim().length !== 0) {
        this.loading = true
        fetch(`${this.api}/login`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive'
          },
          body: JSON.stringify(this.model)
        }).then(res => res.json()).then(res => {
          if (res.code && res.code === 420) {
            this.error = res.messages[0]['values'][0]
          } else {
            if (res.token && res.refresh && res.employee) {
              Utils.storeToken(res.token)
              Utils.storeEmployee(res.employee)
              localStorage.setItem('REFRESH', res.refresh)
              this.$router.push('/')
            } else {
              this.$router.push('/')
            }
          }
        }).catch(err => {
          this.error = err.messages
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.error = 'Entrer toutes vos informations'
      }
    }
  },
  mounted() {
    this.api = Utils.apiUrl()
  }
}
</script>
<style scoped>
.loading {
  margin-right: .325rem;
  animation: rotate 1000ms infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}


</style>
