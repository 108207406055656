<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="d-flex">
          <img :src="employee.avatar" alt="User" width="80">
          <div class="d-flex ml-2 text-white flex-column justify-content-between">
            <b>{{ employee.name }}</b>
            <b>{{ employee.account }}</b>
            <b>{{ employee.role }}</b>
          </div>
        </div>
        <div class="my-6"></div>
        <SponsorshipTable v-if="data.length !== 0" :per-page="15" :data="data" title="Mes parrainages"/>
        <div v-else>
          <router-link :to="{name: 'add-sponsoring'}" type="button" class="btn btn-primary btn-sm text-uppercase"><i
              class="ni ni-fat-add"></i> Ajouter un parrainage
          </router-link>
        </div>
      </div>
    </div>
  </base-header>
</template>

<script>
import SponsorshipTable from "@/views/Tables/SponsorshipTable";
import {Utils} from "@/utils/utils";

export default {
  name: "Sponsorship",
  components: {SponsorshipTable},
  data: () => ({
    data: [],
    api: "",
    employee: {
      avatar: '',
      name: '',
      account: '',
      role: ''
    }
  }),
  methods: {
    async fetchInfo() {
      const token = Utils.token()
      fetch(`${this.api}/employees/${this.employee.account}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'connection': 'keep-alive',
          'employee': this.employee.account,
          'access-token': token
        }
      }).then(res => res.json()).then(res => {
        res.forEach(elt => {
          elt.sponsoring.forEach(item => {
            const line = {
              name: '',
              image: '',
              account: '',
              plan: '',
              country: {
                name: '',
                flag: '',
                code: ''
              },
              phone: '',
              date: new Date()
            }
            line.account = item.sponsoring.client.account.accountNumber
            line.image = item.sponsoring.client.account.user.avatar
            line.name = item.sponsoring.client.account.user.name
            line.phone = item.sponsoring.client.account.user.phoneNumber
            line.date = item.sponsoring.client.registrationDate
            line.plan = 'PLAN ' + item.subscription.billingPlan.name
            line.country.name = item.sponsoring.client.account.user.country.name
            line.country.code = item.sponsoring.client.account.user.country.phoneCode
            line.country.flag = item.sponsoring.client.account.user.country.flag
            this.data.push(line)
          })
        })
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    this.api = Utils.apiUrl()
    const data = JSON.parse(localStorage.getItem('USER'))
    this.employee.avatar = data.account.user.avatar
    this.employee.name = data.account.user.name
    this.employee.account = data.account.accountNumber
    this.employee.role = data.role
    this.fetchInfo()
  }
}
</script>

<style scoped>

</style>