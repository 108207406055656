<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="row">
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="country.code" placeholder="Code"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="country.name" placeholder="Nom du pays"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="country.phoneCode" placeholder="Code téléphonique"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="country.phoneSize" placeholder="Taille des numéros"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <input type="file" class="form-control">
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3 text-white font-weight-800">
            <base-checkbox v-model="country.operational" class="text-uppercase">Opérationnel ?</base-checkbox>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-button @click="action === 'EDIT' ? edit() : add()" class="btn-block" type="primary">Terminer
            </base-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-12">
        <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
             role="alert">
          <strong>{{ error }}</strong>
          <button type="button" class="close"
                  @click="nullError"
                  data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <base-alert class="mt-4" v-if="info.trim().length !== 0" dismissible type="success">
          <strong>
            {{ info }}
          </strong>
        </base-alert>
      </div>
    </div>
  </base-header>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "AddCountry",
  data: () => ({
    action: 'ADD',
    token: '',
    api: '',
    error: '',
    info: '',
    oldCode: '',
    employee: null,
    country: {
      name: '',
      code: '',
      phoneCode: '',
      operational: false,
      phoneSize: ''
    }
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    async add() {
      if (
          this.country.phoneSize.toString().trim().length !== 0 &&
          this.country.phoneCode.toString().trim().length !== 0 &&
          this.country.name.toString().trim().length !== 0 &&
          this.country.code.toString().trim().length !== 0) {
        if (this.country.phoneSize[0] !== '[')
          this.country.phoneSize = `[${this.country.phoneSize}]`
        const formData = new FormData()
        const flag = document.querySelector('input[type=file]').files[0]
        formData.append('flag', flag)
        formData.append('code', this.country.code)
        formData.append('name', this.country.name)
        formData.append('phoneCode', this.country.phoneCode)
        formData.append('phoneSize', this.country.phoneSize)
        formData.append('operational', this.country.operational)
        fetch(`${this.api}/countries`, {
          method: 'POST',
          headers: {
            'connection': 'keep-alive',
            'access-token': this.token,
            'employee': this.employee.account.accountNumber
          },
          body: formData
        }).then(res => res.json()).then(res => {
          if (res.code && res.flag && res.name && res.phoneCode && res.phoneSize) {
            this.info = 'Opération éffectuée'
          }
        }).catch(err => this.error = err.message)
      } else {
        this.error = 'Renseignez tous les champs SVP'
      }
    }
    ,
    async edit() {
      if (
          this.country.phoneSize.toString().trim().length !== 0 &&
          this.country.phoneCode.toString().trim().length !== 0 &&
          this.country.name.toString().trim().length !== 0 &&
          this.country.code.toString().trim().length !== 0) {
        if (this.country.phoneSize[0] !== '[')
          this.country.phoneSize = `[${this.country.phoneSize}]`
        fetch(`${this.api}/countries`, {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'access-token': this.token,
            'employee': this.employee.account.accountNumber
          },
          body: JSON.stringify({...this.country, ...{old: this.oldCode}})
        }).then(res => res.json()).then(res => {
          if (res.code && res.flag && res.name && res.phoneCode && res.phoneSize) {
            this.info = 'Opération éffectuée'
          }
        }).catch(err => this.error = err.message)
      } else {
        this.error = 'Renseignez tous les champs SVP'
      }
    }
  },
  mounted() {
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.token = Utils.token()
    this.api = Utils.apiUrl()
    if (this.$route.params.action !== undefined && this.$route.params.country !== undefined) {
      this.action = this.$route.params.action.toString().toUpperCase()
      this.country.name = this.$route.params.country.name
      this.country.code = this.$route.params.country.code
      this.oldCode = this.country.code
      this.country.phoneCode = this.$route.params.country.phoneCode
      this.country.phoneSize = this.$route.params.country.phoneSize
      this.country.operational = this.$route.params.country.operational
    } else if (this.$route.name === 'edit-country') {
      this.$router.push('add-country')
    }
  }
}
</script>

<style scoped>

</style>