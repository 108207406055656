<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="d-flex mb-6 flex-column flex-md-row">
          <div class="avatar_section">
            <img :src="provider.account.user.avatar" :alt="provider.account.user.name">
          </div>
          <div class="row flex-grow-1 ml-2">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex">
              <div class="info_section d-flex flex-column text-white justify-content-around">
                <b>{{ provider.account.user.name }}</b>
                <b>+{{ provider.account.user.country.phoneCode }} {{ provider.account.user.phoneNumber }}</b>
                <b>{{ provider.account.user.idCardNumber }}</b>
                <b v-if="provider.account.user.email !== null && provider.account.user.email.trim().length !== 0">{{
                    provider.account.user.email
                  }}</b>
                <b class="text-uppercase" v-else>(EMAIL NON RENSEIGNé)</b>
                <b>{{ provider.account.accountNumber }}</b>
                <b>Plan PROVIDER</b>
                <b>DU {{ startDate }} AU {{ endDate }}</b>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex">
              <div class="info_section d-flex flex-column text-white justify-content-around">
                <b>{{ priceFormatter(provider.balanceIn) }} FCFA</b>
                <b>{{ priceFormatter(provider.balanceOut) }} FCFA</b>
                <b>{{ provider.account.user.country.name }}</b>
                <b>{{ provider.city }}</b>
                <b>{{ provider.district }}</b>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex">
              <div class="info_section d-flex flex-column text-white justify-content-around">
                <router-link :to="{name: 'discharge', params: {provider: provider.account.accountNumber}}" type="button"
                             class="btn btn-warning btn-sm text-uppercase"><i
                    class="ni ni-bold-down"></i> decharger
                </router-link>
                <router-link :to="{name: 'supply', params: {provider: provider.account.accountNumber}}" type="button"
                             class="btn btn-success btn-sm text-uppercase"><i
                    class="ni ni-bold-up"></i> recharger
                </router-link>
                <router-link v-if="!provider.locked"
                             :to="{name: 'provider-lock', params: {action: 'lock',provider: provider.account.accountNumber}}"
                             type="button"
                             class="btn btn-danger btn-sm text-uppercase"><i
                    class="ni ni-key-25"></i>Verouiller
                </router-link>
                <router-link v-else
                             :to="{name: 'provider-lock', params: {action: 'unlock', provider: provider.account.accountNumber}}"
                             type="button"
                             class="btn btn-success btn-outline-success btn-sm text-uppercase"><i
                    class="ni ni-lock-circle-open"></i>Dévérouiller
                </router-link>
              </div>
            </div>

          </div>

        </div>
        <TransactionsTable v-if="transactions.length !== 0" :per-page="10" title="Dernières transactions"
                           :data="transactions"/>
      </div>
    </div>
  </base-header>
</template>

<script>
import TransactionsTable from "@/views/Tables/TransactionsTable";
import {Utils} from "@/utils/utils";


export default {
  name: "Provider", components: {TransactionsTable},
  data: () => ({
    account: '',
    key: '',
    api: '',
    startDate: '',
    endDate: '',
    employee: null,
    provider: null,
    transactions: []
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    },
    priceFormatter(price) {
      return Utils.priceFormatter(Number.parseInt(price))
    },
    async fetchProviders() {
      await this.generateToken()
      fetch(`${this.api}/providers/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        if (res.length === 0) this.$router.back()
        this.provider = res[0]
        const d = new Date(this.provider.registrationDate)
        this.startDate = d.toLocaleDateString()
        d.setFullYear(d.getFullYear() + 1)
        this.endDate = d.toLocaleDateString()
      }).catch(err => console.error(err))
    },
    async fetchTransactions() {
      fetch(`${this.api}/providers-transactions/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        if (res.length === 0) this.$router.back()
        this.transactions.push(...res[0].transactions)
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    if (this.$route.params.account === undefined || this.$route.params.account === null)
      this.$router.back()
    this.account = this.$route.params.account
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.fetchProviders().then(() => {
      this.fetchTransactions()
    })
  }
}
</script>

<style scoped>

</style>