<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="d-flex mb-6 flex-column flex-md-row">
          <div class="avatar_section">
            <img :src="employee.account.user.avatar" :alt="employee.account.user.name">
          </div>
          <div class="row flex-grow-1 ml-2">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex">
              <div class="text-uppercase info_section d-flex flex-column text-white justify-content-around">
                <b>{{ employee.account.user.name }}</b>
                <b>+{{ employee.account.user.country.phoneCode }} {{ employee.account.user.phoneNumber }}</b>
                <b>{{ employee.account.user.idCardNumber }}</b>
                <b v-if="employee.account.user.email !== null && employee.account.user.email !== ''">{{
                    employee.account.user.email
                  }}</b>
                <b v-else>(PAS DEMAIL SPéCIFIé)</b>
                <b>{{ employee.account.accountNumber }}</b>
                <b>{{ employee.role }}</b>
                <b></b>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6 col-xl-5 d-flex">
              <div class="text-uppercase info_section d-flex flex-column text-white justify-content-around">
                <b>{{ employee.account.user.country.name }}</b>
                <b>{{ employee.city }}</b>
                <b>{{ employee.district }}</b>
                <b>De {{ records.lastMonth.start }} au {{ records.lastMonth.end }}: {{
                    records.lastMonth.simple.number
                  }} - 300 |
                  {{ records.lastMonth.merchant.number }} - 600</b>
                <b>De {{ records.currentMonth.start }} au {{ records.currentMonth.end }}: {{
                    records.currentMonth.simple.number
                  }} - 300 |
                  {{ records.currentMonth.merchant.number }} - 600</b>
                <b></b>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex">
              <div class="info_section d-flex flex-column text-white justify-content-around">
                <router-link v-if="!employee.locked"
                             :to="{name: 'employee-lock', params: {action: 'lock',employee: employee.account.accountNumber}}"
                             type="button"
                             class="btn btn-danger btn-sm text-uppercase"><i
                    class="ni ni-key-25"></i> Verouiller
                </router-link>
                <router-link v-else
                             :to="{name: 'employee-lock', params: {action: 'unlock',employee: employee.account.accountNumber}}"
                             type="button"
                             class="btn btn-success btn-outline-success btn-sm text-uppercase"><i
                    class="ni ni-lock-circle-open"></i>Dévérouiller
                </router-link>
                <b></b>
                <b></b>
                <b></b>
              </div>
            </div>

          </div>

        </div>
        <TransactionsTable v-if="transactions.length !== 0" :per-page="10" title="Dernières transactions"
                           :data="transactions"/>
      </div>
    </div>
  </base-header>
</template>

<script>
import TransactionsTable from "@/views/Tables/TransactionsTable";
import {Utils} from "@/utils/utils";


export default {
  name: "Employee", components: {TransactionsTable},
  data: () => ({
    transactions: [],
    account: '',
    key: '',
    api: '',
    employee: null,
    sponsoring: [],
    records: {
      lastMonth: {
        simple: {
          number: 0
        },
        merchant: {
          number: 0
        },
        start: '',
        end: ''
      },
      currentMonth: {
        simple: {
          number: 0
        },
        merchant: {
          number: 0
        },
        start: '',
        end: ''
      }
    }
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    }, async fetchEmployees() {
      await this.generateToken()
      fetch(`${this.api}/employees/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(async res => {
        if (res.length === 0) this.$router.back()
        this.employee = await res[0]
        await this.sponsoring.push(...this.employee.sponsoring)
        this.sponsoring.forEach(it => {
          const sponsoring = it.sponsoring
          const subscription = it.subscription
          if (new Date(sponsoring.date).getFullYear() === new Date(subscription.startDate).getFullYear() && new Date(sponsoring.date).getMonth() === new Date(subscription.startDate).getMonth()) {
            const today = new Date()
            if (new Date(sponsoring.date).getMonth() === today.getMonth()) {
              if (subscription.billingPlan.cost === 600) {
                this.records.currentMonth.merchant.number = this.records.currentMonth.merchant.number + 1
              } else {
                this.records.currentMonth.simple.number = this.records.currentMonth.simple.number + 1
              }
            } else if (new Date(sponsoring.date).getMonth() + 1 === today.getMonth()) {

              if (subscription.billingPlan.cost === 600) {
                this.records.currentMonth.merchant.number = this.records.currentMonth.merchant.number + 1

              } else {
                this.records.currentMonth.simple.number = this.records.currentMonth.simple.number + 1

              }
            }
          }

        })
      }).catch(err => console.error(err))
    }, async fetchTransactions() {
      fetch(`${this.api}/employees-transactions/${this.account + ''}`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        console.error(res)
        this.transactions.push(...res)
      }).catch(err => console.error(err))
    }

  },
  mounted() {
    if (this.$route.params.employee === undefined || this.$route.params.employee === null)
      this.$router.back()
    this.account = this.$route.params.employee
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    let today = new Date()
    const last_day_current = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    const last_day_past = new Date(today.getFullYear(), today.getMonth(), 0)
    const first_day_current = new Date(today.getFullYear(), today.getMonth(), 1)
    const first_day_past = new Date(today.getFullYear(), today.getMonth() - 1, 1)
    this.records.lastMonth.end = last_day_past.toDateString()
    this.records.lastMonth.start = first_day_past.toDateString()
    this.records.currentMonth.end = last_day_current.toDateString()
    this.records.currentMonth.start = first_day_current.toDateString()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.fetchEmployees().then(() => {
      this.fetchTransactions()
    })
  }
}
</script>

<style scoped>

</style>