<template>
  <div>
    <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6">
          <stats-card title="utilisateurs"
                      type="gradient-red"
                      :sub-title="summary.clients.total+''"
                      icon="ni ni-single-02"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
          <stats-card title="fournisseurs"
                      type="gradient-default"
                      :sub-title="summary.providers.total+''"
                      icon="ni ni-app"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
          <stats-card title="dépôts"
                      type="gradient-green"
                      :sub-title="priceFormatter(summary.deposits.total)+' FCFA'"
                      icon="ni ni-money-coins"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>

        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 mb-4">
          <stats-card title="retraits"
                      type="gradient-primary"
                      :sub-title="priceFormatter(summary.withdrawals.total)+' FCFA'"
                      icon="ni ni-box-2"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6">
          <stats-card title="recharges"
                      type="gradient-info"
                      :sub-title="priceFormatter(summary.supplies.total)+' FCFA'"
                      icon="ni ni-bold-up"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 mb-4">
          <stats-card title="décharges"
                      type="gradient-warning"
                      :sub-title="priceFormatter(summary.discharges.total)+' FCFA'"
                      icon="ni ni-bold-down"
                      class="mb-4 mb-xl-0"
          >

            <template slot="footer">
              <span class="text-nowrap">DEPUIS LE LANCEMENT</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div v-if="loaded" class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">STATISTIQUES</h6>
                <h5 class="h3 text-white mb-0">TRANSACTIONS MENSUELLES/HEBDOMADAIRE</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 0}"
                       @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">MOIS</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 1}"
                       @click.prevent="initBigChart(1)">
                      <span class="d-none d-md-block">SEMAINE</span>
                      <span class="d-md-none">S</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
                :height="350"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>

          </card>
        </div>


      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table v-if="transactions.length !== 0" :data="transactions"></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table v-if="transactions.length !== 0" :data="transactions"></social-traffic-table>
        </div>
      </div>
      <!--End tables-->
    </div>

  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';

// Tables
import SocialTrafficTable from './Dashboard/SocialTrafficTable';
import PageVisitsTable from './Dashboard/PageVisitsTable';
import {Utils} from "@/utils/utils";


export default {
  components: {
    LineChart,
    PageVisitsTable,
    SocialTrafficTable,
  },
  data: () => ({
    key: '',
    api: '',
    employee: null,
    months: [],
    days: [],
    loaded: false,
    summary: {
      clients: {
        total: 0,
        positive: true,
        difference: 3.6
      },
      providers: {
        total: 0,
        positive: true,
        difference: 3.6
      },
      deposits: {
        total: 0,
        positive: true,
        difference: 3.6
      },
      withdrawals: {
        total: 0,
        positive: true,
        difference: 3.6
      },
      supplies: {
        total: 0,
        positive: true,
        difference: 3.6
      },
      discharges: {
        total: 0,
        positive: true,
        difference: 3.6
      }
    },
    transactions: [],
    bigLineChart: {
      allData: [
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        [
          0, 0, 0, 0, 0, 0, 0
        ]
      ],
      activeIndex: 0,
      chartData: {
        datasets: [],
        labels: [],
      },
      extraOptions: chartConfigs.blueChartOptions,
    }

  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    },
    async fetchTransactions() {
      await this.generateToken()
      fetch(`${this.api}/transactions`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        this.transactions = res
        this.setup()
      }).catch(err => console.error(err))
    },
    getMonth(id) {
      if (id === 'JAN') return 0
      if (id === 'FEV') return 1
      if (id === 'MAR') return 2
      if (id === 'AVR') return 3
      if (id === 'MAI') return 4
      if (id === 'JUI') return 5
      if (id === 'JUL') return 6
      if (id === 'AOU') return 7
      if (id === 'SEP') return 8
      if (id === 'OCT') return 9
      if (id === 'NOV') return 10
      if (id === 'DEC') return 11

    },
    getIndex(key) {
      let index = 0
      for (let i = 0; i <= this.months.length; i++) {
        if (this.months[i].toString().toUpperCase() === key.toString().toUpperCase()) {
          index = i
          break
        }
      }
      return index
    },
    priceFormatter(amount) {
      if (amount === null) amount = 0
      return Utils.priceFormatter(amount)
    },
    dateInterval(day) {
      const date = new Date()
      return date.getTime() - 1000 * 60 * 60 * 24 * day
    },
    setup() {
      //const date = new Date(2020, 11, 31)
      this.transactions.forEach(item => {
        if (new Date(item.date).getMonth() === this.getMonth('DEC')) {
          this.bigLineChart.allData[0][this.getIndex('DEC')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('NOV')) {
          this.bigLineChart.allData[0][this.getIndex('NOV')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('OCT')) {
          this.bigLineChart.allData[0][this.getIndex('OCT')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('SEP')) {
          this.bigLineChart.allData[0][this.getIndex('SEP')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('AOU')) {
          this.bigLineChart.allData[0][this.getIndex('AOU')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('JUL')) {
          this.bigLineChart.allData[0][this.getIndex('JUL')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('JUI')) {
          this.bigLineChart.allData[0][this.getIndex('JUI')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('MAI')) {
          this.bigLineChart.allData[0][this.getIndex('MAI')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('AVR')) {
          this.bigLineChart.allData[0][this.getIndex('AVR')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('MAR')) {
          this.bigLineChart.allData[0][this.getIndex('MAR')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('FEV')) {
          this.bigLineChart.allData[0][this.getIndex('FEV')] += item.amount
        }
        if (new Date(item.date).getMonth() === this.getMonth('JAN')) {
          this.bigLineChart.allData[0][this.getIndex('JAN')] += item.amount
        }
        if (new Date(item.date).getTime() >= this.dateInterval(1)) {
          this.bigLineChart.allData[1][6] += item.amount
        }

        if (
            new Date(item.date).getTime() >= this.dateInterval(2) &&
            new Date(item.date).getTime() < this.dateInterval(1)
        ) {
          this.bigLineChart.allData[1][5] += item.amount
        }

        if (new Date(item.date).getTime() >= this.dateInterval(3) &&
            new Date(item.date).getTime() < this.dateInterval(2)) {
          this.bigLineChart.allData[1][4] += item.amount
        }

        if (new Date(item.date).getTime() >= this.dateInterval(4) &&
            new Date(item.date).getTime() < this.dateInterval(3)) {
          this.bigLineChart.allData[1][3] += item.amount
        }

        if (new Date(item.date).getTime() >= this.dateInterval(5) &&
            new Date(item.date).getTime() < this.dateInterval(4)) {
          this.bigLineChart.allData[1][2] += item.amount
        }

        if (new Date(item.date).getTime() >= this.dateInterval(6) &&
            new Date(item.date).getTime() < this.dateInterval(5)) {
          this.bigLineChart.allData[1][1] += item.amount
        }

        if (new Date(item.date).getTime() >= this.dateInterval(7) &&
            new Date(item.date).getTime() < this.dateInterval(6)) {
          this.bigLineChart.allData[1][0] += item.amount
        }
      })
      this.loaded = true
    }, initBigChart(index) {
      this.bigLineChart.chartData = {
        datasets: [
          {
            label: 'Transaction',
            data: this.bigLineChart.allData[index]
          }
        ],
        labels: index === 0 ? this.months.map(item => item.toString().toUpperCase()) : this.days.map(item => item.toString().toUpperCase()),
      };
      this.bigLineChart.activeIndex = index;
    },
    async fetchSummary() {
      fetch(`${this.api}/summary`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'employee': this.employee.account.accountNumber,
          'refresh-token': this.token
        }
      }).then(res => res.json()).then(res => {
        this.summary = res
      }).catch(err => console.error(err))
    }
  },
  async mounted() {

    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    const labelsM = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"].map(item => item.toString().toUpperCase())
    const labelsD = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"]
    const date = new Date()
    const _months = labelsM.concat(...labelsM)
    const _days = labelsD.concat(...labelsD)
    const days = []
    const months = []
    for (let i = date.getMonth(); i >= 0; i--) {
      months.push(_months[i])
      if (i === 0) {
        i = labelsM.length
      }
      if (months.length === 12) break
    }
    for (let i = date.getDay() - 1; i >= 0; i--) {
      days.push(_days[i])
      if (i === 0) {
        i = labelsD.length
      }
      if (days.length === 7) break
    }

    this.days = days.reverse()
    this.months = months.reverse()
    this.bigLineChart.chartData.labels = this.months
    await this.fetchTransactions()
    this.initBigChart(0);
    await this.fetchSummary()
  }
};
</script>
<style>

</style>
