<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="row">
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="clientNumber" input_type="number" placeholder="Numéro de compte client"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-button @click="associate" class="btn-block" type="primary">Terminer</base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 col-md-6">
        <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
             role="alert">
          <strong>{{ error }}</strong>
          <button type="button" class="close"
                  @click="nullError"
                  data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <base-alert v-if="info.trim().length !== 0" dismissible type="success">
          <strong>
            {{ info }}
          </strong>
        </base-alert>
      </div>
    </div>
  </base-header>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "AddSponsoring",
  data: () => ({
    employee: null,
    clientNumber: '',
    error: '',
    info: '',
    api: ''
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    associate() {
      if (this.clientNumber.trim().length === 8) {
        fetch(`${this.api}/associate-sponsoring`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'access-token': Utils.token()
          },
          body: JSON.stringify({client: this.clientNumber, employee: this.employee.account.accountNumber})
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.info = 'Liaison éffectué'
          } else {
            this.error = res.messages[0]['values'][0]
          }
        }).catch(err => console.error(err))
      } else {
        this.error = 'Saisissez un bon numéro de compte'
      }
    }
  },
  mounted() {
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))

  }
}
</script>

<style scoped>

</style>