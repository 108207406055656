<template>
  <div>
    <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row mt-1">
        <div class="col">
          <div class="row">
            <div class="col col-md-6 col-sm-12">
              <base-input required v-model="accountNumber" placeholder="Numéro de compte"
                          addon-left-icon="ni ni-badge"></base-input>
            </div>

          </div>

          <div class="row">
            <div class="col col-md-6 col-sm-12 flex_">
              <base-button @click="fetchUser" type="primary" class="text-uppercase" icon="ni ni-check-bold">
                Rechercher
              </base-button>
              <i v-if="fetching && !userFound"
                 class="ni ni-settings-gear-65 text-white opacity-7 font-weight-900 text-xl rotate"></i>

            </div>
          </div>

          <div v-if="userFound">

            <div class="row mt-4">
              <div class="col col-12 col-md-6 col-lg-4">
                <base-input placeholder="Nom" v-model="client.name" required
                            addon-left-icon="ni ni-single-02"></base-input>
              </div>
              <div class="col col-12 col-md-6 col-lg-4">
                <base-input placeholder="Numéro de téléphone" required
                            addon-left-icon="ni ni-mobile-button" v-model="client.phone"></base-input>
              </div>
              <div class="col col-12 col-md-6 col-lg-4">
                <base-input placeholder="Adresse email" v-model="client.email"
                            addon-left-icon="ni ni-email-83"></base-input>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <base-input addon-left-icon="ni ni-badge" v-model="client.idCardNumber" input_type="number"
                            placeholder="Numero de CNI"></base-input>
              </div>
              <div class="col col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <select v-model="client.role" name="role" id="role" class="form-control">
                  <option :value="role" v-for="role in roles" :key="role.name">{{ role.value }}</option>
                </select>
              </div>
              <div class="col col-12 col-md-6 col-lg-3">
                <base-input placeholder="Ville" required v-model="client.city"
                            addon-left-icon="ni ni-building"></base-input>
              </div>
              <div class="col col-12 col-md-6 col-lg-3">
                <base-input placeholder="Quartier" required v-model="client.district"
                            addon-left-icon="ni ni-pin-3"></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-button @click="save()" class="text-white text-uppercase" type="primary" icon="ni ni-archive-2">
                  Sauvegarder
                </base-button>
                <i v-if="fetching"
                   class="ni ni-settings-gear-65 text-white opacity-7 font-weight-900 text-xl rotate"></i>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="row">
        <div class="col col-12 col-md-6">
          <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
               role="alert">
            <strong>{{ error }}</strong>
            <button type="button" class="close"
                    @click="nullError"
                    data-dismiss="alert"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <base-alert class="mt-4" v-if="info.trim().length !== 0" dismissible type="success">
            <strong>
              {{ info }}
            </strong>
          </base-alert>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "AddEmployee", data: () => ({
    accountNumber: '',
    error: '',
    info: '',
    api: '',
    fetching: false,
    userFound: false,
    token: '',
    client: {
      uuid: null,
      name: '',
      phone: '',
      email: '',
      city: '',
      district: '',
      idCardNumber: '',
      role: {
        name: 'AGENT',
        value: 'Agent'
      }
    },
    employee: null,
    roles: []
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    async generateToken() {
      this.token = Utils.token()
    },
    async fetchUser() {
      this.fetching = true
      this.error = ''
      this.userFound = false
      if (this.accountNumber.trim().length === 8) {
        await this.generateToken()
        fetch(`${this.api}/clients/${this.accountNumber}`, {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'access-token': this.token,
            'employee': this.employee.account.accountNumber
          }
        }).then(res => res.json()).then(res => {
          if (res.code === 420) {
            this.error = res.messages[0]['values'][0]
          } else {
            if (res.length === 0) {
              this.error = 'Pas d\'utilisateur avec ce numéro dans le système'
            } else {
              const _res = res[0].account
              this.client = {
                uuid: _res.user.uuid,
                name: _res.user.name,
                email: _res.user.email,
                phone: _res.user.phoneNumber,
                idCardNumber: _res.user.idCardNumber,
                city: '',
                district: ''
              }
              this.userFound = true
            }
          }
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.fetching = false
        })
      } else {
        this.error = 'Numéro de compte invalide'
        this.fetching = false
      }
    }, async save() {
      if (this.client.idCardNumber.trim().length !== 0
          && this.client.name.trim().length !== 0
          && this.client.phone.trim().length !== 0
          && this.client.city.trim().length !== 0
          && this.client.district.trim().length !== 0
          && this.accountNumber.toString().trim().length === 8) {
        this.error = ''
        this.fetching = true
        fetch(`${this.api}/add-employee`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'access-token': this.token,
            'employee': this.employee.account.accountNumber
          },
          body: JSON.stringify({...this.client, account: this.accountNumber})
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.userFound = false
            this.info = "Employé enregistré"
          } else {
            this.error = res.messages[0]['values'][0]
          }
        }).catch(err => console.error(err)).finally(() => {
          this.fetching = false
        })


      } else {
        this.error = 'Remplissez tous les champs !'
      }
    }
  }, mounted() {
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.api = Utils.apiUrl()
    this.roles = Utils.roles()

  }
}
</script>

<style scoped>
.flex_ {
  display: flex !important;
  align-items: center !important;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}


</style>