<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="row">
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="provider" placeholder="Numéro de compte fournisseur"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="amount" placeholder="Montant de la transaction"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <input v-model="code" class="form-control" placeholder="Code de l'agent" type="password"/>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-button @click="discharge" class="btn-block" type="primary">Terminer</base-button>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col">
        <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
             role="alert">
          <strong>Oups! </strong> {{ error }}
          <button type="button" class="close"
                  @click="nullError"
                  data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <base-alert v-if="info.toString().length !== 0" type="primary" dismissible>
          <strong>Terminé !</strong> {{ info }}
        </base-alert>
        <base-alert v-if="success.toString().length !== 0" type="success" dismissible>
          <strong>Bravo !</strong> {{ success }}
        </base-alert>
      </div>
    </div>
  </base-header>
</template>

<script>

import {Utils} from "@/utils/utils";
import io from 'socket.io-client'

const socket = io(Utils.socketUrl(), {
  transports: ['websocket']
})

export default {
  name: "Discharge",
  data: () => ({
    key: '',
    api: '',
    provider: '',
    employee: null,
    amount: '0',
    code: '',
    error: '',
    info: '',
    success: '',
    token: ''
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    async generateToken() {
      this.token = Utils.token()
    }, priceFormatter(price) {
      return Utils.priceFormatter(Number.parseInt(price))
    },
    async discharge() {
      if (this.provider.trim().length !== 8) {
        this.error = 'Numéro de compte incorrect'
        return
      }

      if (this.provider.trim().length === 0 && this.provider.trim() === '0') {
        this.error = 'Montant incorrect'
        return
      }

      if (this.code.trim().length !== 4) {
        this.error = 'Code sécret incorrect'
        return
      }

      await this.generateToken()

      fetch(`${this.api}/init-discharge`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'accept': 'keep-alive',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        },
        body: JSON.stringify({
          "provider": this.provider,
          "employee": this.employee.account.accountNumber,
          "code": this.code,
          "amount": Number.parseInt(this.amount.length === 0 ? '0' : this.amount)
        })
      }).then(res => res.json()).then(res => {
        if (res.code === 420) {
          this.error = res.messages[0]['values'][0]
        } else {
          if (res.success === true) {
            this.info = 'Décharge initiée, demandez au fournisseur de confirmer l\'opération'
          } else {
            this.$router.push('/')
          }
        }
      }).catch(err => console.error(err))

    }
  },
  beforeDestroy() {
    socket.disconnect()
  },
  mounted() {
    if (this.$route.params.provider === undefined || this.$route.params.provider === null)
      this.$router.back()
    this.provider = this.$route.params.provider
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()

    socket.on('connect', () => {
      if (socket.connected) {
        socket.emit('join', this.employee.account.accountNumber)
      }
    })

    socket.on('dischargeConfirmed', d => {
      const data = JSON.parse(d)
      if (data.uuid !== undefined && data.uuid.toString().trim().length !== 0 && data.provider !== undefined && data.provider.toString() === this.provider.toString()) {
        this.info = ''
        this.success = 'Recharge validée par le provider.'
      }
    })
  }
}
</script>

<style scoped>

</style>