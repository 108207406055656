<template>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="row mt-1">
      <div class="col">
        <div class="row">
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="fee.min" placeholder="Montant min"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="fee.max" placeholder="Montant max"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-input v-model="fee.value" placeholder="Valeur"></base-input>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3 text-white font-weight-800">
            <base-checkbox v-model="fee.static">STATIC ?</base-checkbox>
          </div>
          <div class="col col-12 col-md-6 col-lg-4 col-xl-3">
            <base-button @click="action === 'EDIT' ? update() : save()" class="btn-block" type="primary">Terminer
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <div v-if="error.toString().length !== 0" class="alert alert-danger mt-4 alert-dismissible fade show"
             role="alert">
          <strong>{{ error }}</strong>
          <button type="button" class="close"
                  @click="nullError"
                  data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <base-alert class="mt-4" v-if="info.trim().length !== 0" dismissible type="success">
          <strong>
            {{ info }}
          </strong>
        </base-alert>
      </div>
    </div>
  </base-header>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "AddTransactionFee",
  data: () => ({
    api: '',
    token: '',
    action: 'ADD',
    employee: null,
    error: '',
    info: '',
    fee: {
      uuid: '',
      min: 0,
      max: 0,
      value: 0,
      static: false
    }
  }),
  methods: {
    nullError() {
      this.error = ''
    },
    async save() {
      if (this.fee.max.toString().length !== 0 && this.fee.value.toString().length !== 0 && this.fee.min.toString().length !== 0) {
        fetch(`${this.api}/transaction-fees`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'employee': this.employee.account.accountNumber,
            'access-token': this.token
          },
          body: JSON.stringify(this.fee)
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.info = 'Plan ajouté'
          } else {
            if (res.code !== undefined) {
              this.error = res.messages[0]['values'][0]
            } else {
              this.error = 'Une erreur est survenue !'
            }
          }
        }).catch(err => this.error = err.message())
      } else {
        this.error = 'Renseignez tous les champs'
      }
    },
    async update() {
      if (this.fee.max.toString().length !== 0 && this.fee.value.toString().length !== 0 && this.fee.min.toString().length !== 0) {
        fetch(`${this.api}/transaction-fees`, {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            'connection': 'keep-alive',
            'employee': this.employee.account.accountNumber,
            'access-token': this.token
          },
          body: JSON.stringify(this.fee)
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.info = 'Plan modifié'
          } else {
            if (res.code !== undefined) {
              this.error = res.messages[0]['values'][0]
            } else {
              this.error = 'Une erreur est survenue !'
            }
          }
        }).catch(err => this.error = err.message())
      } else {
        this.error = 'Renseignez tous les champs'
      }
    }
  }, mounted() {
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.token = Utils.token()
    this.api = Utils.apiUrl()
    if (this.$route.params.action !== undefined && this.$route.params.action === 'EDIT' && this.$route.params.item !== undefined) {
      this.action = 'EDIT'
      this.fee.value = this.$route.params.item.fee
      this.fee.min = this.$route.params.item.minAmount
      this.fee.max = this.$route.params.item.maxAmount
      this.fee.static = this.$route.params.item._static
      this.fee.uuid = this.$route.params.item.uuid
    }
  }
}
</script>

<style scoped>

</style>