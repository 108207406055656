<template>
  <div class="card shadow bg-default">
    <div class="card-header border-0 bg-default">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0 text-white">
            {{ title }}
          </h3>
        </div>

        <div class="col text-right">
          <router-link :to="{name: 'add-transaction-fee'}" type="button" class="btn btn-primary btn-sm text-uppercase">
            <i
                class="ni ni-fat-add"></i> Ajouter
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-items-center table-dark table-flush">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="sort" data-sort="name">MONTANT MIN</th>
          <th scope="col" class="sort" data-sort="name">MONTANT MAX</th>
          <th scope="col" class="sort" data-sort="name">FRAIS</th>
          <th scope="col" class="sort" data-sort="name">STATIC</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="list">
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <b>{{ `${priceFormatter(item.minAmount)} FCFA` }}</b>
          </td>
          <td class="budget">
            <b>{{ `${priceFormatter(item.maxAmount)} FCFA` }}</b>
          </td>
          <td>
            <b>{{ item._static ? `${priceFormatter(item.fee)} FCFA` : `${(item.fee * 100).toFixed(2)} %` }}</b>
          </td>
          <td class="text-uppercase">
            <b>{{ item._static ? 'OUI' : 'NON' }}</b>
          </td>
          <td>
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <router-link :to="{name: 'edit-transaction-fee', params: {item, action: 'EDIT'}}" class="dropdown-item">
                  <i class="ni ni-air-baloon"></i><b class="text-uppercase">Modifier</b>
                </router-link>
              </template>
            </base-dropdown>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "TransactionFeeTable",
  props: {
    title: String,
    data: {
      type: Array
    }
  },
  methods: {
    priceFormatter: function (price) {
      return Utils.priceFormatter(price)
    }
  }
}
</script>

<style scoped>

</style>
