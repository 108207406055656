<template>
  <div>
    <base-header type="gradient-dark" class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                 style="min-height: 400px;">
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col">
            <h1 class="display-3 text-white">Salut {{ model.name.split(' ')[0] }},</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">

          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img :src="model.avatar" :alt="model.name"
                         class="rounded-circle">
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">

            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ model.name }}
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ model.city }}, {{ model.country }}
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>{{ model.role }}
                </div>
                <div>
                  <i class="ni education_hat mr-2"></i>POSSA
                </div>
                <hr class="my-4"/>

              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Mon compte</h3>
                </div>
                <div class="col-4 text-right">
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">INFORMATIONS DE L'EMPLOYé</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Nom et prénom"
                                  placeholder="Nom et prénom"
                                  input-classes="form-control-alternative"
                                  v-model="model.name"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Email address"
                                  placeholder="Adresse email"
                                  input-classes="form-control-alternative"
                                  v-model="model.email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Ville"
                                  placeholder="Ville"
                                  input-classes="form-control-alternative"
                                  v-model="model.city"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Quartier"
                                  placeholder="Quartier"
                                  input-classes="form-control-alternative"
                                  v-model="model.district"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Téléphone"
                                  placeholder="Téléphone"
                                  input-classes="form-control-alternative"
                                  v-model="model.phoneNumber"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input alternative
                                  label="Numéro CNI"
                                  placeholder="Numéro CNI"
                                  input-classes="form-control-alternative"
                                  v-model="model.idCardNumber"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="errorData.toString().trim().length !== 0">
                    <div class="col">
                      <div class="alert alert-danger alert-dismissible fade show"
                           role="alert">
                        <strong>{{ errorData }}</strong>
                        <button type="button" class="close"
                                @click="nullErrorData"
                                data-dismiss="alert"
                                aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="infoData.toString().trim().length !== 0">
                    <div class="col">
                      <base-alert dismissible type="success">
                        <strong>
                          {{ infoData }}
                        </strong>
                      </base-alert>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <base-button @click="update()" type="primary">Mettre à jour</base-button>
                      <i v-if="changingData"
                         class="ni ni-settings-gear-65 text-primary opacity-7 font-weight-900 text-xl rotate"></i>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Changer le mot de passe</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Mot de passe actuel"
                                  placeholder="Mot de passe actuel"
                                  input-classes="form-control-alternative"
                                  v-model="password.current"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Nouveau mot de passe"
                                  placeholder="Nouveau mot de passe"
                                  input-classes="form-control-alternative"
                                  v-model="password.new"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Confirmation "
                                  placeholder="Confirmation "
                                  input-classes="form-control-alternative"
                                  v-model="password.confirm"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="errorPassword.toString().trim().length !== 0">
                    <div class="col">
                      <div class="alert alert-danger alert-dismissible fade show"
                           role="alert">
                        <strong>{{ errorPassword }}</strong>
                        <button type="button" class="close"
                                @click="nullErrorPassword"
                                data-dismiss="alert"
                                aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="infoPassword.toString().trim().length !== 0">
                    <div class="col">
                      <base-alert dismissible type="success">
                        <strong>
                          {{ infoPassword }}
                        </strong>
                      </base-alert>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-button @click="changePassword()" type="primary">changer le mot de passe
                      </base-button>
                      <i v-if="changingPassword"
                         class="ni ni-settings-gear-65 text-primary opacity-7 font-weight-900 text-xl rotate"></i>
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Utils} from "@/utils/utils";

export default {
  name: 'user-profile',
  data() {
    return {
      changingPassword: false,
      changingData: false,
      api: '',
      token: '',
      errorData: '',
      infoData: '',
      errorPassword: '',
      infoPassword: '',
      model: {
        name: '',
        email: '',
        district: '',
        idCardNumber: '',
        phoneNumber: '',
        city: '',
        avatar: '',
        role: '',
        country: ''
      },
      password: {
        account: '',
        current: '',
        new: '',
        confirm: '',
      }
    }
  },
  methods: {
    nullErrorData() {
      this.errorData = ''
    },
    nullErrorPassword() {
      this.errorPassword = ""
    },
    async update() {
      if (this.model.name !== "" && this.model.district !== "" && this.model.idCardNumber !== "" && this.model.city && this.model.phoneNumber !== "") {
        this.changingData = true
        fetch(`${this.api}/edit-employee`, {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            'access-token': this.token,
            'connection': 'keep-alive',
            'employee': this.employee.account.accountNumber
          },
          body: JSON.stringify({...this.model, account: this.employee.account.accountNumber})
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.employee.account.user.name = this.model.name
            this.employee.account.user.email = this.model.email
            this.employee.account.user.phoneNumber = this.model.phoneNumber
            this.employee.account.user.idCardNumber = this.model.idCardNumber
            this.employee.city = this.model.city
            this.employee.district = this.model.district
            Utils.storeEmployee(this.employee)
            this.infoData = "Modifications éffectuées"
          } else {
            if (res.code !== undefined) {
              this.errorData = res.messages[0]['values'][0]
            } else {
              this.errorData = "Une erreur est survenue"
            }
          }
        }).catch(err => {
          this.errorData = err.message
        }).finally(() => this.changingData = false)
      } else {
        this.errorData = "Renseignez tous les champs SVP !"
      }
    },
    async changePassword() {
      if (this.password.confirm !== "" && this.password.current !== "" && this.password.new !== "") {
        this.changingPassword = true
        fetch(`${this.api}/change-password`, {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            'access-token': this.token,
            'connection': 'keep-alive',
            'employee': this.employee.account.accountNumber
          },
          body: JSON.stringify({...this.password, accountNumber: this.employee.account.accountNumber})
        }).then(res => res.json()).then(res => {
          if (res.success && res.success === true) {
            this.infoPassword = "Mot de passe mis à jour."
          } else {
            if (res.code !== undefined) {
              this.errorPassword = res.messages[0]['values'][0]
            } else {
              this.errorPassword = "Une erreur est survenue"
            }
          }
        }).catch(err => this.errorPassword = err.message).finally(() => this.changingPassword = false)
      } else {
        this.errorPassword = "Renseignez tous les champs SVP !"

      }
    }
  },
  mounted() {
    const data = JSON.parse(localStorage.getItem('USER'))
    this.employee = data
    this.api = Utils.apiUrl()
    this.token = Utils.token()
    this.model.name = data.account.user.name
    this.model.city = data.city
    this.model.district = data.district
    this.model.idCardNumber = data.account.user.idCardNumber
    this.model.phoneNumber = data.account.user.phoneNumber
    this.model.email = data.account.user.email
    this.model.avatar = data.account.user.avatar
    this.model.country = data.account.user.country.name
    this.model.role = data.role
    this.password.account = data.account.accountNumber
  }
};
</script>
<style scoped>

.rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}


</style>
