<template>
  <div>
    <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row mt-1">
        <div class="col">
          <UsersTable v-if="clients.length !== 0" :per-page="10" title="Liste des utilisateurs" :data="clients"/>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import UsersTable from "@/views/Tables/UsersTable";
import {Utils} from "@/utils/utils";


export default {
  name: "Users",
  components: {UsersTable},
  data: () => ({
    clients: [],
    token: '',
    key: '',
    api: '',
    employee: null
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    },
    async fetchClients() {
      await this.generateToken()
      fetch(`${this.api}/clients`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        this.clients.push(...res)
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.fetchClients()
  }
}
</script>

<style scoped>

</style>