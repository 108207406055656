<template>
  <div class="card shadow bg-default">
    <div class="card-header border-0 bg-default">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0 text-white">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <router-link :to="{name: 'addEmployee'}" type="button" class="btn btn-primary btn-sm text-uppercase"><i
              class="ni ni-fat-add"></i> Ajouter
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-items-center table-dark table-flush">
        <thead class="thead-dark">
        <tr>
          <th scope="col" class="sort" data-sort="name">NOM</th>
          <th scope="col" class="sort" data-sort="name">COMPTE</th>
          <th scope="col" class="sort" data-sort="name">POSTE</th>
          <th scope="col" class="sort" data-sort="name">PAYS, VILLE</th>
          <th scope="col" class="sort" data-sort="name">TELEPHONE</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="list">
        <tr v-for="(item, index) in displayData" :key="index">
          <td>
            <div class="media align-items-center bg-transparent">
              <a href="javascript:void(0)" class="avatar rounded-circle mr-3 w-12 bg-transparent">
                <img class="w-12 h-12" :alt="item.account.user.name" :src="item.account.user.avatar">
              </a>
              <div class="media-body">
                <b class="name mb-0 text-sm text-uppercase">{{ item.account.user.name }}</b>
              </div>
            </div>
          </td>
          <td>
            <b>{{ item.account.accountNumber }}</b>
          </td>
          <td class="budget">
            <b class="text-uppercase"> {{ item.role }}</b>
          </td>
          <td>
            <b>
              <div class="media align-items-center bg-transparent">
                <a href="javascript:void(0)" class="avatar rounded-circle mr-3 w-12 bg-transparent">
                  <img class="w-12 h-12" :alt="item.account.user.country.name" :src="item.account.user.country.flag">
                </a>
                <div class="media-body">
                  <b class="name mb-0 text-sm text-uppercase">{{ item.account.user.country.name }}, {{ item.city }}</b>
                </div>
              </div>
            </b>
          </td>
          <td>
            <b>{{ `+${item.account.user.country.phoneCode} ${item.account.user.phoneNumber}` }}</b>
          </td>
          <td>
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <router-link :to="{name: 'employee', params: {employee: item.account.accountNumber}}"
                             class="dropdown-item">
                  <i class="ni ni-air-baloon"></i><b class="text-uppercase">Transactions</b>
                </router-link>

                <router-link
                    :to="{name: 'employee-lock', params: {action: item.locked ? 'unlock': 'lock',employee: item.account.accountNumber}}"
                    class="dropdown-item">
                  <i class="ni ni-key-25"></i><b class="text-uppercase">verouiller</b>
                </router-link>
              </template>
            </base-dropdown>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer d-flex justify-content-end mt-4 bg-default">
      <base-pagination :next-page="nextPage" :prev-page="prevPage" :go-to="goTo" :per-page="perPage"
                       :total="data.length" :value="currentPage"/>
    </div>

  </div>
</template>

<script>
import {Utils} from '@/utils/utils'

export default {
  name: "EmployeesTable",
  props: {
    title: String,
    data: {
      type: Array
    },
    perPage: Number,

  },
  data: () => ({
    displayData: [],
    currentPage: 1,
  }),
  methods: {
    prevPage: function () {
      this.currentPage = this.currentPage === 0 ? 0 : this.currentPage - 1
    },
    nextPage: function () {
      this.currentPage = this.currentPage === Math.ceil(this.data.length / this.perPage) ? Math.ceil(this.data.length / this.perPage) : this.currentPage + 1
    },
    goTo: function (index) {
      this.currentPage = index
    },
    priceFormatter: function (price) {
      return Utils.priceFormatter(price)
    }
  },
  mounted() {
    this.displayData = this.data.slice(0, this.perPage)
  },
  watch: {
    currentPage: function (_new) {
      this.displayData = this.data.slice((_new - 1) * this.perPage, this.perPage * _new)
    }
  }
}
</script>

<style scoped>
.w-12 {
  width: 32px !important;
}

.h-12 {
  width: 32px !important;
}

</style>